<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			:direction="direction"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>新增商机</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="chanceTitle">
						<div class="inputtitle">
							<span>*</span>
							商机标题
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.chanceTitle" placeholder="请输入商机标题"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="" prop="chanceDesc">
						<div class="inputtitle">
							<span>*</span>
							需求描述
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.chanceDesc" placeholder="请输入需求描述"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="" prop="" style="margin-bottom: 0;">
						<div class="inputtitle" >
							<span>*</span>
							报价方案
						</div>
						<el-upload
							class="upload-demo"
							ref="upload"
							:action="$baseUrl + '/common/uploadFile'"
							:show-file-list="false"
							name="file"
							:on-success="baojiaUpFileSuccess"
							
						>
							<div slot="tip" class="el-upload__tip">上传文件类型：jpeg/jpg/png/mp4/xlsx/xls/doc/docx</div>
							<div class="upbox nochoosetext" style="">
								<i class="el-icon-plus"></i>
								<span>点击上传</span>
							</div>
						</el-upload>
						<template v-for="(item, index) in ruleForm.docList">
							<div class="box1" :key="index" v-if="item.docType == '0'">
								<div class="box2">
									<img src="../../../assets/customer/image5.png" />
									<span>{{ item.docName }}</span>
								</div>
								<img class="deleteicon" src="@/assets/customer/image4.png" @click="deleteDoc(index)"/>
							</div>
						</template>
						
					</el-form-item>

					<el-form-item label="" prop="">
						<div class="inputtitle" style="margin-top: 20px;">
							<span>*</span>
							设计方案
						</div>
						<el-upload
							class="upload-demo"
							ref="upload"
							:action="$baseUrl + '/common/uploadFile'"
							:show-file-list="false"
							name="file"
							:on-success="shejiUpFileSuccess"
							
						>
							<div slot="tip" class="el-upload__tip">上传文件类型：jpeg/jpg/png/mp4/xlsx/xls/doc/docx</div>
							<div class="upbox nochoosetext" style="">
								<i class="el-icon-plus"></i>
								<span>点击上传</span>
							</div>
						</el-upload>
						<template v-for="(item, index) in ruleForm.docList">
							<div class="box1" :key="index" v-if="item.docType == '1'">
								<div class="box2">
									<img src="../../../assets/customer/image5.png" />
									<span>{{ item.docName }}</span>
								</div>
								<img class="deleteicon" src="@/assets/customer/image4.png" @click="deleteDoc(index)"/>
							</div>
						</template>
					</el-form-item>
				</el-form>

				<div class="twobtn">
					<div @click="resetForm">取消</div>
					<div class="save" @click="submitForm()">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { editCustomerChance } from '@/network/api.js';
export default {
	data() {
		return {
	
			drawer: false,
			direction: 'rtl',
			value1: '',
			wayIndex: 0,
			docInfo: {
				docName: '', //文档原始名称
				docPath: '', //文档路径
				docType: '' //文档类型 0 报价方案 1 设计方案
			},
			ruleForm: {
				chanceDesc: '', //商机描述
				chanceTitle: '', //商机标题
				customerId: '', //客户id
				docList: [] ,//报价or设计方案文档
				chanceId: '',
			},
			rules: {
				chanceDesc: [{ required: true, message: '请输入商机描述', trigger: 'blur' }],
				chanceTitle: [{ required: true, message: '请输入商机标题', trigger: 'blur' }]
			}
		};
	},
	methods: {
		// 点击删除上传的文件
		deleteDoc(index) {
			this.ruleForm.docList.splice(index,1)
		},
		// 上传报价文件成功回调 设计方案上传
		baojiaUpFileSuccess(info,file) {
			if (info.status !== 1) return this.$myMessage(info.message,"error")

			let fileinfo = {
				docName: file.name,  		//文档原始名称
				docPath: info.data.fileUrl,  		//文档路径
				docType: '0',  		//文档类型 0 报价方案 1 设计方案
			}

			
			this.ruleForm.docList.push(fileinfo)
	
		},
		// 上传设计文件成功回调 设计方案上传
		shejiUpFileSuccess(info,file) {
			if (info.status !== 1) return this.$myMessage(info.message,"error")

			let fileinfo = {
				docName: file.name,  		//文档原始名称
				docPath: info.data.fileUrl,  		//文档路径
				docType: '1',  		//文档类型 0 报价方案 1 设计方案
			}
		
			this.ruleForm.docList.push(fileinfo)
			
		},

		// 新增客户商机
		async _editCustomerChance() {
			const res = await editCustomerChance(this.ruleForm);
			this.$myMessage('编辑成功', 'success');
			this.resetForm();
			this.$emit('updataInfo');
		},
		submitForm() {
			console.log(this.ruleForm);
			// return
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					// console.log(this.ruleFrom);
					// return;
					this._editCustomerChance();
				} else {
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
			this.ruleForm.docList = []
		}
	}
};
</script>

<style scoped lang="scss">
	::v-deep {
		.el-form-item__content {
			
		}
	}
.deleteicon {
	cursor: pointer;
	width: 16px;
	height: 16px;
}
.box2 {
	display: flex;
	align-items: center;
	img {
		width: 18px;
		height: 17px;
		margin: 0 12px 0 0;
	}
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
	}
}
.box1:hover {
	background-color: #faf9fb;
}
.box1 {
	margin: 0 0 15px 17px;
	width: 598px;
	height: 34px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding: 0 20px 0 12px;
}
.upbox:active {
	opacity: 0.6;
}
.upbox {
	cursor: pointer;
	margin: 0 0 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 108px;
	height: 32px;
	background: rgba(46, 119, 246, 0.08);
	border-radius: 4px;
	border: 1px solid #2e77f6;
	i {
		color: #3286ff;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
		margin: 0 0 0 1em;
	}
}

.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}

::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}

.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
